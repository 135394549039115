<template>
  <div class="row d-flex justify-center align-center mr-0 ml-0 mb-20">
    <v-card class="tgc-content-card w-100 p-4 mb-16" elevation="0">
      <slot></slot>
    </v-card>
  </div>
</template>

<script>
export default {};
</script>

<style lang="sass">
@import '@/assets/scss/md/_colors'

.tgc-content-card
  padding-top: 100px !important
</style>