<template>
  <div>
    <div class="top-header-card" :style="`width: ${windowWidth - 300}px`"></div>
    <v-card class="tgc-header-card w-300 pl-4 pt-1 ml-6" color="primary" dark>
      <slot></slot>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "mobile-table-header",
  watch: {},
  components: {},
  data: () => ({
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth,
  }),
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
      window.addEventListener("orientationchange", this.onOrientationChange);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    },
    onOrientationChange() {
      this.orientationScreen = window.orientation;
    },
  },
};
</script>
<style lang="sass">
@import '@/assets/scss/md/_colors'
.tgc-header-card
  top: 50px
  height: 30px
  z-index: 10
  padding-left: 40px !important
  background: linear-gradient (60deg, $mainTheme-primary, $mainTheme-primary) !important
  box-shadow: 0 12px 20px -10px rgb(35 197 119 / 28%), 0 4px 20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(35 197 119 / 20%) !important
  h1
    font-size: 1rem !important
    font-weight: 300
    margin: 0
.w-300
  width: 300px
.top-header-card
  height: 1px
  background-color: #0000000d
  border-bottom: 2px solid #0000000d
  position: relative
  top: 65px
  left: 300px
  z-index: 5
</style>